$(document).ready(function () {

    // $('#sidebarCollapse').on('click', function (e) {
    //     e.stopPropagation();
    //     $('#sidebar').toggleClass('active');
    // });

    // $('#sidebarClose').on('click', function (e) {
    //     e.stopPropagation();
    //     $('#sidebar').toggleClass('active');
    // });

    // window.addEventListener('load', (event) => {
    //     document.getElementById("sidebarCollapse").onclick = function toggleClass() {
    //         var menu = document.getElementById("sidebar");
    //         menu.classList.toggle('active');
    //     }
    //         document.getElementById("sidebarClose").onclick = function closeSidebar() {
    //             var menu = document.getElementById("sidebar");
    //             menu.classList.toggle("active");
    //         }
    // });

    // window.onload = function(){

    //     document.querySelectorAll('nav ul li ul li a').forEach(item => {

    //         item.addEventListener('click', event => {

    //           var el = $("nav ul li ul li a").removeClass("activeSubLink");
    //           console.log(el);

    //            var element = event.path[0];

    //              element.classList.add("activeSubLink");


    //         })

    //     })

    // }



});


