 
window.onload = function(){


//console.log("did i make it here");

  $(function() {
    $('.circular-bar-chart').easyPieChart({
      accX: 0,
          accY: -150,
          delay: 1,
          barColor: '#3c8873',
          trackColor: '#a2d3c5',
          scaleColor: false,
          scaleLength: 5,
          lineCap: 'round',
          lineWidth: 13,
          size: 175,
          rotate: 0,
          animate: ({
              duration: 0,
              enabled: false
          })
    });
   
  });
   
  $(function() {
    $('.circular-bar-chart-2').easyPieChart({
      accX: 0,
          accY: -150,
          delay: 1,
          barColor: '#486ba8',
          trackColor: '#b2c2dd',
          scaleColor: false,
          scaleLength: 5,
          lineCap: 'round',
          lineWidth: 13,
          size: 175,
          rotate: 0,
          animate: ({
              duration: 0,
              enabled: false
          })
    });
   
  });




}

  
   
       